import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  basicColumnChart: any;
  splineAreaChart: any;
  statData!: any;
  status7: any;

  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 2,
    decimalPlaces: 2,
  };

  ngOnInit(): void {
    this.breadCrumbItems = [
      {label: 'Dashboard', active: true}
    ];

    this.fetchData();

    this._splineAreaChart('["--vz-success", "--vz-danger"]');
    this._status7('["#45CB85", "#3577f1", "#4b38b3", "#8d6e63", "#878a99", "#f06548"]');
  }

  private fetchData() {
    this.statData = [
      {
        title: 'Completed',
        value: 387,
        icon: 'ri-shield-keyhole-fill'
      },
      {
        title: 'To process',
        value: 32,
        icon: 'ri-contacts-book-upload-fill'
      },
      {
        title: 'Applicants',
        value: 489,
        icon: 'ri-shield-user-fill'
      },
      {
        title: 'Members',
        value: 620,
        icon: 'ri-group-2-fill'
      },
      {
        title: 'Contacts',
        value: 300,
        icon: 'ri-user-search-fill'
      }
    ];
  }

  private _status7(colors: any) {
    colors = this.getChartColorsArray(colors);
    this.status7 = {
      series: [125, 42, 58, 58, 89, 89],
      labels: ["Completed", "By Correction", "In Progress", "Archived", "Draft", "Cancelled"],
      chart: {
        type: "donut",
        height: 180,
      },
      plotOptions: {
        pie: {
          offsetX: 0,
          offsetY: 0,
          donut: {
            size: "70%",
            labels: {
              show: false,
            }
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        lineCap: "round",
        width: 0
      },
      colors: colors
    };
  }

  private getChartColorsArray(colors: any) {
    colors = JSON.parse(colors);
    return colors.map(function (value: any) {
      var newValue = value.replace(" ", "");
      if (newValue.indexOf(",") === -1) {
        var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
        if (color) {
          color = color.replace(" ", "");
          return color;
        }
        else return newValue;
      } else {
        var val = value.split(',');
        if (val.length == 2) {
          var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
          rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
          return rgbaColor;
        } else {
          return newValue;
        }
      }
    });
  }

  setbalancevalue(value: any) {
    if (value == 'today') {
      this.splineAreaChart.series = [{
        name: 'Current Period',
        data: [20, 25, 30, 35, 40, 55, 70, 110, 150, 180, 210, 250]
      }, {
        name: 'Last Period',
        data: [12, 17, 45, 42, 24, 35, 42, 75, 102, 108, 156, 199]
      }]
    }
    if (value == 'last_week') {
      this.splineAreaChart.series = [{
        name: 'Current Period',
        data: [30, 35, 40, 45, 20, 45, 20, 100, 120, 150, 190, 220]
      }, {
        name: '2 Periods Ago',
        data: [12, 17, 45, 52, 24, 35, 42, 75, 92, 108, 146, 199]
      }]
    }
    if (value == 'last_month') {
      this.splineAreaChart.series = [{
        name: 'Current Period',
        data: [20, 45, 30, 35, 40, 55, 20, 110, 100, 190, 210, 250]
      }, {
        name: '3 Periods Ago',
        data: [62, 25, 45, 45, 24, 35, 42, 75, 102, 108, 150, 299]
      }]
    }
    if (value == 'current_year') {
      this.splineAreaChart.series = [{
        name: 'Current Period',
        data: [27, 25, 30, 75, 70, 55, 50, 120, 250, 180, 210, 250]
      }, {
        name: '4 Periods Ago',
        data: [12, 17, 45, 42, 24, 35, 42, 75, 102, 108, 156, 199]
      }]
    }
  }

  private _splineAreaChart(colors: any) {
    colors = this.getChartColorsArray(colors);
    this.splineAreaChart = {
      series: [{
        name: 'Current Period',
        data: [20, 25, 30, 35, 40, 55, 70, 110, 150, 180, 210, 250]
      }, {
        name: 'Last Period',
        data: [12, 17, 45, 42, 24, 35, 42, 75, 102, 108, 156, 199]
      }],
      chart: {
        height: 290,
        type: 'area',
        toolbar: 'false',
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        max: 260
      },
      colors: colors,
      fill: {
        opacity: 0.06,
        type: 'solid'
      }
    };
  }

  setstatusvalue(value: any) {
    if (value == 'all') {
      this.status7.series = [125, 42, 58, 89]
    }
    if (value == '7') {
      this.status7.series = [25, 52, 158, 99]
    }
    if (value == '30') {
      this.status7.series = [35, 22, 98, 99]
    }
    if (value == '90') {
      this.status7.series = [105, 32, 68, 79]
    }
  }
}
