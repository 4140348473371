<app-breadcrumbs title="Dashboard" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>

<div class="row">
  <div class="col-xl-12">
    <div class="card crm-widget">
      <div class="card-body p-0">
        <div class="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
          @for(stat of statData;track $index){
            <div>
              <app-kpi-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon"
                            [profit]="stat.profit"></app-kpi-stat>
            </div>
          }
        </div><!-- end row -->
      </div><!-- end card body -->
    </div><!-- end card -->
  </div><!-- end col -->
</div><!-- end row -->

<div class="row">
  <div class="col-xxl-6">
    <div class="card card-height-100">
      <div class="card-header align-items-center d-flex">
        <h4 class="card-title mb-0 flex-grow-1">Completed Policies</h4>
        <div class="flex-shrink-0">
          <div class="dropdown card-header-dropdown" ngbDropdown>
            <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);"
               data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
              <span class="fw-semibold text-uppercase fs-12">Compare with: </span><span
              class="text-muted">Current Period<i class="mdi mdi-chevron-down ms-1"></i></span>
            </a>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
              <a class="dropdown-item" href="javascript:void(0);"
                 (click)="setbalancevalue('today')">Last period</a>
              <a class="dropdown-item" href="javascript:void(0);"
                 (click)="setbalancevalue('last_week')">2 periods ago</a>
              <a class="dropdown-item" href="javascript:void(0);"
                 (click)="setbalancevalue('last_month')">3 periods ago</a>
              <a class="dropdown-item" href="javascript:void(0);"
                 (click)="setbalancevalue('current_year')">4 periods ago</a>
            </div>
          </div>
        </div>
      </div><!-- end card header -->
      <div class="card-body px-0">
        <div class="mb-n3">
          <apx-chart [series]="splineAreaChart.series" [chart]="splineAreaChart.chart"
                     [dataLabels]="splineAreaChart.dataLabels" [stroke]="splineAreaChart.stroke"
                     [xaxis]="splineAreaChart.xaxis" [yaxis]="splineAreaChart.yaxis"
                     [colors]="splineAreaChart.colors" [fill]="splineAreaChart.fill" dir="ltr"></apx-chart>
        </div>
      </div>
    </div><!-- end card -->
  </div><!-- end col -->

  <div class="col-xxl-6">
    <div class="card card-height-100">
      <div class="card-header align-items-center d-flex">
        <h4 class="card-title mb-0 flex-grow-1">Policy Status</h4>
        <div class="hstack gap-1">
          <div class="dropdown card-header-dropdown" ngbDropdown>
            <a class="dropdown-btn text-muted arrow-none" href="javascript:void(0);"
               data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
              All Time <i class="mdi mdi-chevron-down ms-1"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
              <a class="dropdown-item" href="javascript:void(0);" (click)="setstatusvalue('all')">All
                Time</a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="setstatusvalue('7')">Last 7
                Days</a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="setstatusvalue('30')">Last 30
                Days</a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="setstatusvalue('90')">Last 90
                Days</a>
            </div>
          </div>
        </div>
      </div><!-- end card header -->
      <div class="card-body p-0">
        <div class="row g-0">
          <div class="col-xxl-8">
            <div class="">
              <div class="card-body">
                <div class="row g-0 text-center">
                <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                  <p class="fw-medium mb-0"><i
                    class="ri-checkbox-blank-circle-fill status-processed align-middle me-2"></i> Completed</p>
                  <div>
                    <span class="text-muted">125 Policies</span>
                  </div>
                </div><!-- end -->
                <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                  <p class="fw-medium mb-0"><i
                    class="ri-checkbox-blank-circle-fill status-by-correction align-middle me-2"></i> By Correction
                  </p>
                  <div>
                    <span class="text-muted">42 Policies</span>
                  </div>
                </div><!-- end -->
                <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                  <p class="fw-medium mb-0"><i
                    class="ri-checkbox-blank-circle-fill status-to-process align-middle me-2"></i> To Process
                  </p>
                  <div>
                    <span class="text-muted">58 Policies</span>
                  </div>
                </div><!-- end -->
                <div class="d-flex justify-content-between py-2">
                  <p class="fw-medium mb-0"><i
                    class="ri-checkbox-blank-circle-fill status-archived align-middle me-2"></i> Archived</p>
                  <div>
                    <span class="text-muted">89 Policies</span>
                  </div>
                </div><!-- end -->
                <div class="d-flex justify-content-between py-2">
                  <p class="fw-medium mb-0"><i
                    class="ri-checkbox-blank-circle-fill status-draft align-middle me-2"></i> Draft</p>
                  <div>
                    <span class="text-muted">89 Policies</span>
                  </div>
                </div><!-- end -->
                <div class="d-flex justify-content-between py-2">
                  <p class="fw-medium mb-0"><i
                    class="ri-checkbox-blank-circle-fill status-cancelled align-middle me-2"></i> Cancelled</p>
                  <div>
                    <span class="text-muted">89 Policies</span>
                  </div>
                </div><!-- end -->
              </div>
              </div>
            </div>
          </div>

          <div class="col-xxl-4">
            <div class="border-start p-4 h-100 d-flex flex-column">
              <div class="w-100">
                <div class="d-flex align-items-center">
                  <apx-chart class="apex-charts" dir="ltr" [series]="status7.series" [labels]="status7.labels"
                             [chart]="status7.chart" [plotOptions]="status7.plotOptions" [dataLabels]="status7.dataLabels"
                             [legend]="status7.legend" [stroke]="status7.stroke" [colors]="status7.colors" dir="ltr"></apx-chart>
                </div>
                <div class="mt-3">
                  <div class="d-flex justify-content-center align-items-center mb-4">
                    <h2 class="me-3 ff-secondary mb-0">258</h2>
                    <div>
                      <p class="text-muted mb-0">Total Policies</p>
                      <p class="text-success fw-medium mb-0">
                                <span class="badge bg-success-subtle text-success p-1 rounded-circle"><i
                                  class="ri-arrow-right-up-line"></i></span> +3 New
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
